@font-face {
    font-family: 'PDMS_Saleem_QuranFont';
    src: url('./fonts/PDMS_Saleem_ACQuranFont_shipped.ttf') format('truetype');
}

@font-face {
    font-family: 'PDMS_IslamicFont';
    src: url('./fonts/PDMS_IslamicFont_Android_shipped.ttf') format('truetype');
}

@font-face {
    font-family: 'PDMS_NastaliqNafees_iphone';
    src: url('./fonts/PDMS_NastaliqNafees_iphone.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.react-tel-input {
    left: 0;
}

.react-tel-input .flag-dropdown{
    margin: 3px;
    /* border: none; */
}
.react-tel-input .form-control {
    width: 100% !important;
    height: 56px !important;
    border: 1px solid #cbcbcb;
    background-color: white !important;
    /* height: 3.9999em !important; */
}

.react-tel-input {
    height: 56px;
}

.react-tel-input .form-control:focus {
    box-shadow: none;
    border: 2px solid #2e7d32;
}

.form-select {
    border: 1px solid #cbcbcb;
    height: 56px;
    /* appearance: none; */
    font-weight: 300;
    border-radius: 5px;
    padding: 0px 10px;
    width: 100%;
}

.form-select:focus {
    box-shadow: none;
    border: 2px solid #2e7d32;

}

/* .css-shtuzs-MuiInputBase-root-MuiFilledInput-root{
    background-color: white !important;
} */

.form-select.order {
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    height: 56px;
    background: white;
    color: #717171;
    /* padding: 16.5px 14px; */
    /* appearance: none; */
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    align-items: baseline !important;
}

body {
    background-color: #EFEFEF;
    color: black;
    overflow-y: scroll;
    min-height: 100%;
}

.full-vp{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.flex-1{
    flex: 1;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    body {
        background-color: black;
        color: white;
        overflow-y: scroll;
    }
    hr {
        border-top: 1px solid rgb(255 245 245 / 91%);
    }
    .card-hr hr{
        border-top: 1px solid rgba(0,0,0,.1);
    }
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #EA6724;
    font-weight: 500;
    font-size: 18px;
}
.navbar-light .navbar-nav .nav-link{
    color: #000000;
    font-weight: 500;
    font-size: 18px;
}
.bg-light{
    background-color: white !important;
}

.orangeText{
 color: #FF8A00;
}

.black-font{
    color: #000000;
}

.orangeBg{
    background-color: #FF8A00;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: unset;
    bottom: unset;
    left: unset;
    padding: 3.25rem;
    height: 100%;
}
.card{
    border-color: white;
    cursor: pointer;
    border-radius: 16px;
}
.card:hover{
    border-color: rgba(186, 186, 186, 0.8);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.box-shadow{
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.w_bg{
    background-color: white;
}
button{
    outline: none !important;
}